<template>
  <div class="paypal-main">
    <div
      ref="paypal"
      class="paypal-wrapper"
    />

    <div
      v-if="showStripe"
      class="stripe-pay"
    >
      <div
        class="button"
        @click="handleStripePay"
      >
        Pay by Card
      </div>
    </div>
  </div>
</template>

<script>
// /* eslint-disable */
// import { addScript } from '@/utils'
// const PaypalUrl = 'https://www.paypal.com/sdk/js?client-id=AdpaWeKLd1eM1foOeZ1zaEmM-7BpopT1UMKNCVG5yOMHGmSYVOvWKCf38qZdzrdvjP1j1E8MAnhST-t7&components=buttons&vault=true'
// addScript(PaypalUrl)
import {
  paypalOrderSuccess,
  paypalOrderFailed
} from '@/utils'
import { stripePayments } from '@/api/pay.js'
let paypalButtons
const VUE_APP_PAYPAL_CLIENTID = process.env.VUE_APP_PAYPAL_CLIENTID2

export default {
  name: 'PaypalIndex',
  metaInfo: {
    script: [
      { src: `https://www.paypal.com/sdk/js?client-id=${VUE_APP_PAYPAL_CLIENTID}&components=buttons&vault=true` }
    ]
  },
  data () {
    return {
      currencyCode: '',
      customId: '',
      amount: '',
      showStripe: false
    }
  },
  mounted () {
    this.initPaypal()
  },
  created () {
    const { currencyCode, customId, amount, version } = this.$route.query
    this.showStripe = version >= 2
    this.currencyCode = currencyCode
    this.customId = customId
    this.amount = amount
    if (['dev', 'sit'].includes(process.env.VUE_APP_ENVIRONMENT)) {
      console.log(this.$route.query)
      console.log(window.webkit)
    }
  },
  methods: {
    initPaypal () {
      if (!window.paypal) {
        setTimeout(() => {
          this.initPaypal()
        }, 40)
      } else {
        this.updateButtons()
      }
    },
    updateButtons () {
      const paypal = window.paypal
      if (paypalButtons && paypalButtons.close) {
        paypalButtons.close()
      }
      const { currencyCode, amount, customId } = this
      paypalButtons = paypal.Buttons({
        style: {
          layout: 'horizontal',
          color: 'gold',
          shape: 'rect',
          label: 'paypal'
        },
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: currencyCode,
                  value: amount
                },
                custom_id: customId,
                description: 'cdk'
              }
            ]
          })
        },
        onApprove: (data, actions) => {
          paypalOrderSuccess(data).then(res => {
            console.log(res)
          })
        },
        onError: (data) => {
          paypalOrderFailed(data)
        }
      })
      paypalButtons.render(this.$refs.paypal)
    },
    handleStripePay () {
      stripePayments({
        app: 'netbang',
        ticket: this.customId,
        successUrl: location.protocol + '//' + location.host + '/paypal/success',
        cancelUrl: location.href
      })
        .then(res => {
          if (res.code === 200) {
            window.open(res.data.url)
          } else {
            this.$toast.fail({
              message: res.msg
            })
          }
        })
    }
  }
}
</script>

<style lang="less">
    .paypal-main{
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        .paypal-wrapper{
            width: 600px;
            display: flex;
            justify-content: space-around;
        }
        .stripe-pay {
            margin-top: 30px;
            width: 600px;
            .button {
              width: 100%;
              margin: 0 auto;
              // min-width: 500PX;
              max-width: 750PX;
              height: 55PX;
              background: #ffc439;
              color: black;
              border-radius: 4PX;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 20PX;
              cursor: pointer;
            }

            @media only screen and (min-width: 150px) {
              .button {
                height: 25PX;
              }
            }

            @media only screen and (min-width: 200px) {
              .button {
                height: 35PX;
              }
            }

            @media only screen and (min-width: 375px) {
              .button {
                height: 45PX;
              }
            }

             @media only screen and (min-width: 625px) {
               .button {
                  height: 55PX;
               }
            }
        }
    }
</style>
